<template>
    <section class="c-register">
        <div class="c-register__body">

            <app-intro>
                <h1>Registreren</h1>
            </app-intro>

            <register-form theme="light"/>

        </div>
    </section>
</template>

<script>
    import AppIntro from '../../components/app/AppIntro.vue';
    import RegisterForm from '../../components/forms/RegisterForm.vue';

    export default {
        components: { RegisterForm, AppIntro },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-register {
        display: grid;
        height: 100%;
        -ms-grid-columns: 1fr;

        &__body {
            align-self: center;
            justify-self: center;
            width: 85%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }
</style>
