<template>
    <form v-on:submit.prevent="handleSubmit" :class="'form' + (theme !== '' ? ' form--' + theme : '')" novalidate>
        <div class="form-fieldset">

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.firstname.$error, 'has-success': submitted && !$v.form.firstname.$error }">
                <label class="form-label" for="register-firstname">Voornaam</label>
                <input type="text" v-model="form.firstname" id="register-firstname" name="firstname" class="form-control" placeholder="Vul hier uw voornaam in" />
                <div v-if="submitted && $v.form.firstname.$error" class="form-validation">
                    <span v-if="!$v.form.firstname.required">Voornaam is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.lastname.$error, 'has-success': submitted && !$v.form.lastname.$error }">
                <label class="form-label" for="register-lastname">Achternaam</label>
                <input type="text" v-model="form.lastname" id="register-lastname" name="lastname" class="form-control" placeholder="Vul hier uw achternaam in" />
                <div v-if="submitted && $v.form.lastname.$error" class="form-validation">
                    <span v-if="!$v.form.lastname.required">Achternaam is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.company.$error, 'has-success': submitted && !$v.form.company.$error }">
                <label class="form-label" for="register-company">Bedrijfsnaam</label>
                <input type="text" v-model="form.company" id="register-company" name="company" class="form-control" placeholder="Vul hier uw bedrijfsnaam in" />
                <div v-if="submitted && $v.form.company.$error" class="form-validation">
                  <span v-if="!$v.form.company.minLength">Minimaal 2 karakters</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.address.$error, 'has-success': submitted && !$v.form.address.$error }">
                <label class="form-label" for="register-address">Adres</label>
                <input type="text" v-model="form.address" id="register-address" name="address" class="form-control" placeholder="Vul hier uw adres in" />
                <div v-if="submitted && $v.form.address.$error" class="form-validation">
                    <span v-if="!$v.form.address.required">Adres is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.postcode.$error, 'has-success': submitted && !$v.form.postcode.$error }">
                <label class="form-label" for="register-postcode">Postcode</label>
                <input type="text" v-model="form.postcode" id="register-postcode" name="postcode" class="form-control" placeholder="Vul hier uw postcode in" />
                <div v-if="submitted && $v.form.postcode.$error" class="form-validation">
                    <span v-if="!$v.form.postcode.required">Postcode is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.city.$error, 'has-success': submitted && !$v.form.city.$error }">
                <label class="form-label" for="register-city">Woonplaats</label>
                <input type="text" v-model="form.city" id="register-city" name="city" class="form-control" placeholder="Vul hier uw woonplaats in" />
                <div v-if="submitted && $v.form.city.$error" class="form-validation">
                    <span v-if="!$v.form.city.required">Woonplaats is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.county.$error, 'has-success': submitted && !$v.form.county.$error }">
                <label class="form-label" for="register-county">Provincie</label>
                <select v-model="form.county" id="register-county" name="county" class="form-control">
                    <option disabled selected>Kies uw provincie</option>
                    <option value="Groningen">Groningen</option>
                    <option value="Friesland">Friesland</option>
                    <option value="Noord Holland">Noord Holland</option>
                    <option value="Zuid Holland">Zuid Holland</option>
                    <option value="Zeeland">Zeeland</option>
                    <option value="Drenthe">Drenthe</option>
                    <option value="Noord brabant">Noord brabant</option>
                    <option value="Gelderland">Gelderland</option>
                    <option value="Overijssel">Overijssel</option>
                    <option value="Utrecht">Utrecht</option>
                    <option value="Flevoland">Flevoland</option>
                    <option value="Limburg">Limburg</option>
                </select>
                <div v-if="submitted && $v.form.county.$error" class="form-validation">
                  <span v-if="!$v.form.county.required">Provincie is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.phone.$error, 'has-success': submitted && !$v.form.phone.$error }">
                <label class="form-label" for="register-phone">Telefoonnummer</label>
                <input type="text" v-model="form.phone" id="register-phone" name="phone" class="form-control" placeholder="Vul hier uw telefoon nummer in" />
                <div v-if="submitted && $v.form.phone.$error" class="form-validation">
                    <span v-if="!$v.form.phone.required">Telefoonnummer is verplicht</span>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': submitted && $v.form.email.$error, 'has-success': submitted && !$v.form.email.$error }">
                <label class="form-label" for="login-email">Email</label>
                <input type="email" v-model="form.email" id="login-email" name="email" class="form-control" placeholder="Vul hier uw email in" />
                <div v-if="submitted && $v.form.email.$error" class="form-validation">
                    <span v-if="!$v.form.email.required">Email is verplicht</span>
                    <span v-if="!$v.form.email.email">Email is ongeldig</span>
                </div>
            </div>
        </div>

        <div class="form-fieldset">
            <div class="form-group" :class="{ 'has-error': submitted && $v.form.password.$error, 'has-success': submitted && !$v.form.password.$error }">
                <label class="form-label" for="register-password">Wachtwoord</label>
                <input type="password" v-model="form.password" id="register-password" name="password" class="form-control" placeholder="Vul hier uw wachtwoord in" />
                <div v-if="submitted && $v.form.password.$error" class="form-validation">
                    <span v-if="!$v.form.password.required">Wachtwoord is verplicht</span>
                    <span v-if="!$v.form.password.minLength">Wachtwoord moet minstens 6 karakters lang zijn</span>
                </div>
            </div>
            <div class="form-group" :class="{ 'has-error': submitted && $v.form.passwordRepeat.$error, 'has-success': submitted && !$v.form.passwordRepeat.$error }">
                <label class="form-label" for="register-password-repeat">Herhaal wachtwoord</label>
                <input type="password" v-model="form.passwordRepeat" id="register-password-repeat" name="password-repeat" class="form-control" placeholder="Herhaal uw wachtwoord" />
                <div v-if="submitted && $v.form.passwordRepeat.$error" class="form-validation">
                    <span v-if="!$v.form.passwordRepeat.required">Herhaal uw wachtwoord</span>
                    <span v-else-if="!$v.form.passwordRepeat.sameAsPassword">Wachtwoorden moeten overeen komen</span>
                </div>
            </div>
        </div>

        <div class="form-fieldset form-fieldset--horizontal">
            <div class="custom-checkbox" style="grid-column: 1 / -1;" :class="{ 'has-error': submitted && $v.form.privacy.$error }">
                <label for="register-privacy" class="custom-control-label">
                    <input type="checkbox" v-model="form.privacy" name="privacy" id="register-privacy" class="custom-control-input" />
                    <span class="custom-control-name">Ja, ik ga akkoord met de Severfield <a href="https://www.vsc.nl/privacy-verklaring" target="_blank">Privacy Verklaring</a></span>
                </label>
                <div v-if="submitted && $v.form.privacy.$error" class="form-validation">
                    <span v-if="!$v.form.privacy.checked">U dient akkoord te gaan</span>
                </div>
            </div>

            <button type="submit" id="ga-register-submit" class="[ c-btn c-btn--primary ] form-submit-btn" :disabled="status.registering">
                <svg v-show="!status.registering" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span v-show="status.registering"><spinner/></span>
                <span v-show="!status.registering">Registreren</span>
            </button>

			<!--<a href="https://voortmandesignandbuild.nl/contact-voortman-design-and-build/" target="_blank" class="c-btn c-btn--tint" style="white-space: nowrap;">Vrijblijvend adviesgesprek</a>-->
        </div>
    </form>
</template>

<script>
    import { mapState } from 'vuex';
    import {
        required,
        email,
        minLength,
        sameAs,
    } from 'vuelidate/lib/validators';
    import normalizeFormData from '../../helpers/normalizeFormData';
    import * as ls from '../../helpers/localStorage';
    import router from '../../helpers/router';
    import { accountService } from '../../services';
    import Spinner from '../Spinner.vue';

    export default {
        name: 'RegisterForm',
        components: { Spinner },
        data () {
            return {
                foundDuplicateEmail: false,
                submitted: false,
                form: {
                    company: '',
                    firstname: '',
                    lastname: '',
                    address: '',
                    postcode: '',
                    city: '',
                    county: 'Kies uw provincie',
                    phone: '',
                    password: '',
                    passwordRepeat: '',
                    email: ls.get('request:email'),
                    privacy: '',
                },
            };
        },
        props: {
            theme: {
                type: String,
                default: '',
            },
        },
        validations: {
            form: {
                company: { minLength: minLength(2) },
                firstname: { required },
                lastname: { required },
                address: { required },
                postcode: { required },
                city: { required },
                county: { required },
                phone: { minLength: minLength(6) },
                password: { required, minLength: minLength(6) },
                passwordRepeat: { required, sameAsPassword: sameAs('password') },
                email: { required, email },
                privacy: {
                    checked: (value) => value === true,
                },
            },
        },
        computed: {
            ...mapState('account', ['status']),
        },
        methods: {
            handleSubmit (e) {
                this.submitted = true;

                // validate the form
                this.$v.form.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.form.$pending || this.$v.form.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                this.$store.commit('account/registerRequest');

                // register new account
                accountService
                    .register(data)
                    .then(() => {
                        this.$store.commit('account/registerSuccess');

                        // emit event to parent
                        this.$emit('emailExists', false);

                        // reset errors
                        this.$store.dispatch('alert/clear');

                        // show success message
                        this.$store.dispatch('alert/success', 'Uw account is aangemaakt', { root: true });

                        // remove success message after 2 seconds
                        setTimeout(() => {
                            this.$store.dispatch('alert/clear');
                        }, 2000);

                        // if url contains 'register' redirect to account
                        if (router.currentRoute.path.indexOf('register') > 0)
                        {
                            this.$router.push('/account');
                        }
                        // else flag account as logged in after registering
                        else
                        {
                            this.$store.commit('account/loginSuccess');
                            this.$store.dispatch('account/user');
                        }
                    })
                    .catch((error) => {
                        this.$store.commit('account/registerFailure');
                        this.$store.dispatch('alert/error', error, { root: true });

                        if (error.response.data.emailExists)
                        {
                            // emit event to parent
                            this.$emit('emailExists', true);

                            // reset validation
                            this.submitted = false;
                            this.$v.$reset();
                        }
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .custom-control-name {
        font-size: rem(16);
        font-weight: $font-weight-normal;

        &:after {
            left: rem(4);
        }
    }
</style>
